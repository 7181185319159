/** @jsx jsx */
import { useState } from 'react'
import { LogoImage } from '@/data'
import { Col, Container, Row } from 'react-bootstrap'

import { Link } from 'gatsby'
import { jsx } from '@emotion/react'

import {
  header,
  logo,
  mainMenu,
  submenu,
  socialMedia,
  navLinksSection,
} from '../assets/styles/Header.styles'
import { NavLinks } from '../data'

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false)

  return (
    <header
      css={header}
      className={`${true === sticky ? 'fixedHeader animated flipInX' : null}`}
      id='header'
    >
      <Container className='headerContainer'>
        <Row>
          <Col css={logo} lg={3} md={3} sm={6}>
            <div >
              <Link to='/'>
                <img src={LogoImage.light} alt='' />
              </Link>
            </div>
          </Col>
          <Col
            css={navLinksSection}
            lg={6}
            sm={6}
            md={6}
            className='d-none d-lg-block '
          >
            <nav css={mainMenu} className='text-center'>
              <ul>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? 'menu-item-has-children'
                          : ''
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul css={submenu} className='sub-menu'>
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  )
                })}
              </ul>
            </nav>
          </Col>
          <Col css={socialMedia} lg={3} sm={6} md={3}>
            <nav className='nav-social'>
              <a target='_blank' href='https://www.instagram.com/versar.socioemocional/'>
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M16.2741 1H5.7259C3.11586 1 1 3.21579 1 5.94909V16.0509C1 18.7842 3.11586 21 5.7259 21H16.2741C18.8841 21 21 18.7842 21 16.0509V5.94909C21 3.21579 18.8841 1 16.2741 1Z'
                    stroke='#375F92'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                  />
                  <path
                    d='M11.0038 6.13037C8.43291 6.13037 6.35352 8.31363 6.35352 11.0003C6.35352 13.6869 8.43831 15.8702 11.0038 15.8702C13.5693 15.8702 15.6541 13.6869 15.6541 11.0003C15.6541 8.31363 13.5693 6.13037 11.0038 6.13037Z'
                    stroke='#375F92'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                  />
                  <path
                    d='M17.6576 4.55204C17.6576 5.13462 17.2093 5.60407 16.653 5.60407C16.0967 5.60407 15.6484 5.13462 15.6484 4.55204C15.6484 3.96946 16.0967 3.5 16.653 3.5C17.2093 3.5 17.6576 3.96946 17.6576 4.55204Z'
                    stroke='#375F92'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                  />
                </svg>
              </a>
              <a target='_blank' href='https://facebook.com/versar.socioemocional'>
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    className='facebookIcon'
                    d='M9.56472 16.9617H11.8602V10.9436H13.4589L13.6317 8.93006H11.8602V7.78187C11.8602 7.30676 11.952 7.1201 12.3895 7.1201H13.6317V5.02734H12.0438C10.3371 5.02734 9.56472 5.81354 9.56472 7.32372V8.93006H8.37109V10.9719H9.56472V16.9617Z'
                    fill='#375F92'
                  />
                  <path
                    d='M16.2741 1H5.7259C3.11586 1 1 3.21579 1 5.94909V16.0509C1 18.7842 3.11586 21 5.7259 21H16.2741C18.8841 21 21 18.7842 21 16.0509V5.94909C21 3.21579 18.8841 1 16.2741 1Z'
                    stroke='#375F92'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                  />
                </svg>
              </a>
            </nav>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default HeaderOne
