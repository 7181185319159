/** @jsx jsx */
import { css } from '@emotion/react'

export const facebook = css`
  &:hover {
    path:nth-of-type(1) {
      fill: #badfe5;
    }
    path:nth-of-type(2) {
      stroke: #badfe5;
    }
  }
  &:active {
    path:nth-of-type(1) {
      fill: #ef7b8e;
    }
    path:nth-of-type(2) {
      stroke: #ef7b8e;
    }
  }
`

export const instagram = css`
  &:hover {
    path {
      stroke: #badfe5;
    }
  }
  &:active {
    path {
      stroke: #ef7b8e;
    }
  }
`

export const linkedin = css`
  &:hover {
    path:nth-of-type(1) {
      stroke: #badfe5;
    }
    path:nth-of-type(2) {
        fill: #badfe5;
    }
    path:nth-of-type(3) {
        fill: #badfe5;
    }
    path:nth-of-type(4) {
        fill: #badfe5;
    }
  }
  &:active {
    path:nth-of-type(1) {
        stroke: #ef7b8e;
    }
    path:nth-of-type(2) {
        fill: #ef7b8e;
    }
    path:nth-of-type(3) {
        fill: #ef7b8e;
    }
    path:nth-of-type(4) {
        fill: #ef7b8e;
    }
  }
`
