/** @jsx jsx */
import { jsx } from '@emotion/react'
import { instagram } from '../assets/styles/Icons.styles'

const InstagramIcon = () => {
  return (
    <svg
    css={instagram}
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M16.2741 0.996094H5.7259C3.11586 0.996094 1 3.21188 1 5.94519V16.047C1 18.7803 3.11586 20.9961 5.7259 20.9961H16.2741C18.8841 20.9961 21 18.7803 21 16.047V5.94519C21 3.21188 18.8841 0.996094 16.2741 0.996094Z'
        stroke='white'
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
      <path
        d='M11.0038 6.12646C8.43291 6.12646 6.35352 8.30972 6.35352 10.9964C6.35352 13.683 8.43831 15.8663 11.0038 15.8663C13.5693 15.8663 15.6541 13.683 15.6541 10.9964C15.6541 8.30972 13.5693 6.12646 11.0038 6.12646Z'
        stroke='white'
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
      <path
        d='M17.6576 4.54813C17.6576 5.13071 17.2093 5.60017 16.653 5.60017C16.0967 5.60017 15.6484 5.13071 15.6484 4.54813C15.6484 3.96555 16.0967 3.49609 16.653 3.49609C17.2093 3.49609 17.6576 3.96555 17.6576 4.54813Z'
        stroke='white'
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
    </svg>
  )
}

export default InstagramIcon
