/** @jsx jsx */
import { css } from '@emotion/react'
export const sectionStyles = css`
  background-color: #375F92;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  @media (max-width: 850px) {
    .container {
      padding: 0;
      max-width: 100%;
    }
  }
  .title-content {
    h1 {
      color: #fff;
      font-size: 38px;
      font-weight: 700;
      line-height: 45.6px;
      margin-top: 90px;
      width: 339px;
      @media (max-width: 850px) {
        width: 100%;
        text-align: center;

      }
    }
    p {
      width: 440px;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 24px;
    }
    @media (max-width: 850px) {
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 44px;
        font-weight: 700;
        line-height: 48.4px;
        margin-bottom: 40px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;
      }
      p {
        color:#FFFFFF; 
        font-size: 18px;
        line-height:27px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;

      }
      img {
        margin-bottom: 56px;
        width: 350px;
        @media (max-width: 850px) {
        margin: 0;
      }
      }
      .img-container {
        display: flex;
        justify-content: center;
        @media (max-width: 850px) {
        margin: 0;
        padding: 0;
        img{
          margin-top: 56px;
          width: 100%;
        }
      }
      }
    }
  }
`


