/** @jsx jsx */
import { jsx } from '@emotion/react'
import { styles } from '../assets/styles/DemoSection.styles'
import { Col, Container, Row } from 'react-bootstrap'
import whitelabel from '../assets/images/LP-images/illustration-demo.png'
const Demo = () => {
  return (
    <section css={styles}>
      <Container>
        <Row className='row'>          
          <Col className='textContent' data-aos='fade-right'>
            <h1>Versar para ir além</h1>
            <p>
            A escola testemunha o desenrolar de importantes etapas do desenvolvimento humano, sendo um dos principais cenários onde experiências marcantes e transformadoras acontecem. <span>É reconhecendo esse valor que, como parceiro da comunidade escolar, promovemos o diálogo, o conhecimento e a troca de experiências, para que no fim dessa jornada, o estudante tenha consciência de suas possibilidades.</span>
            </p>
            <a href='#formulario'>Quero uma demonstração</a>
          </Col>
          <Col className='whiteLabel' data-aos='fade-left'>
            <img src={whitelabel} alt='Aplicação customizável' />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Demo
