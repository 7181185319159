/** @jsx jsx */
import { jsx } from '@emotion/react'
import { facebook } from '../assets/styles/Icons.styles'


const FacebookIcon = () => {
  return (
    <svg
      css={facebook}
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M9.56472 16.9578H11.8602V10.9397H13.4589L13.6317 8.92615H11.8602V7.77796C11.8602 7.30285 11.952 7.1162 12.3895 7.1162H13.6317V5.02344H12.0438C10.3371 5.02344 9.56472 5.80964 9.56472 7.31982V8.92615H8.37109V10.968H9.56472V16.9578Z'
        fill='white'
      />
      <path
        d='M16.2741 0.996094H5.7259C3.11586 0.996094 1 3.21188 1 5.94519V16.047C1 18.7803 3.11586 20.9961 5.7259 20.9961H16.2741C18.8841 20.9961 21 18.7803 21 16.047V5.94519C21 3.21188 18.8841 0.996094 16.2741 0.996094Z'
        stroke='white'
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
    </svg>
  )
}

export default FacebookIcon
