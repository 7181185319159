/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { sectionStyles } from '../assets/styles/Incentives.styles'

import VersarShapes from '../assets/images/LP-images/versar-shapes.png'

const Incentives = () => {
  return (
    <section css={sectionStyles}>
      <Container>
        <Row className='title-content'>
          <Col className='col-text' lg={6} md={12} sm={12} data-aos='fade-right'>
            <h1>Incentivamos a escola autoral</h1>
            <p>
              Além disso, também <strong>viabilizamos a produção de conteúdos socioemocionais de autoria da própria escola,</strong> com toda a assistência e treinamento necessários para que se torne uma autoridade no assunto, com sua própria voz.
            </p>
            <p>
              Construímos, juntos, a abordagem socioemocional adequada para engajar professores, alunos e famílias!
            </p>
          </Col>
          <Col
            className='img-container'
            data-aos='fade-left'
            lg={6}
            md={12}
            sm={12}
          >
            <img src={VersarShapes} alt='Formas geometricas do versar' />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Incentives
