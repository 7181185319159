/** @jsx jsx */
import { css } from '@emotion/react'
export const sectionStyles = css`
  background-color: #BADFE5;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  @media (max-width: 850px) {
    .container {
      padding: 0;
      max-width: 100%;
    }
  }

  .col-text{
    margin-top: 103px;
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #BADFE5;
      margin:0;
      padding-top: 56px;
      padding-bottom: 64px;
    }
  }
  .title-content {
    h1 {
      color: #375F92;
      width: 460px;
      font-size: 38px;
      font-weight: 700;
      line-height: 45.6px;
      @media (max-width: 850px) {
        width: 100%;
        text-align: center;
      }
    }
    p {
      width: 539px;
      color: #666;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 32px;
    }
    @media (max-width: 850px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 38px;
        font-weight: 700;
        line-height: 45.6px;
        margin-bottom: 16px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;
      }
      p {
        font-size: 18px;
        line-height: 27px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
        @media (max-width: 850px) {
        margin: 0;
      }
      }
      .img-container {
        display: flex !important;
        align-self: flex-end !important;
      }
    }
  }
`


