/** @jsx jsx */
import { jsx } from '@emotion/react'
import { styles } from '../assets/styles/card.styles'

function Card({ data }) {
    return (
        <div data-aos='fade-left' css={styles}>
            <img src={data.img} alt={data.title} />
            <div className='content-text'>
                <h3>{data.title}</h3>
                <h5>{data.resume}</h5>
                {data?.topics?.map((topic) => <p>{topic}</p>)}
            </div>
        </div>
    )
}

export default Card
