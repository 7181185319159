/** @jsx jsx */
import { jsx } from '@emotion/react'
import { linkedin } from '../assets/styles/Icons.styles'

const LinkedinIcon = () => {
  return (
    <svg
      css={linkedin}
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M16.2741 0.996094H5.7259C3.11586 0.996094 1 3.21188 1 5.94519V16.047C1 18.7803 3.11586 20.9961 5.7259 20.9961H16.2741C18.8841 20.9961 21 18.7803 21 16.047V5.94519C21 3.21188 18.8841 0.996094 16.2741 0.996094Z'
        stroke='white'
        stroke-width='1.5'
        stroke-miterlimit='10'
      />
      <path
        d='M8.43696 9.36133H6.50879V15.8941H8.43696V9.36133Z'
        fill='white'
      />
      <path
        d='M7.46481 8.50716C8.09673 8.50716 8.60443 7.96983 8.60443 7.30241C8.60443 6.63499 8.09133 6.09766 7.46481 6.09766C6.83829 6.09766 6.3252 6.63499 6.3252 7.30241C6.3252 7.96983 6.83829 8.50716 7.46481 8.50716Z'
        fill='white'
      />
      <path
        d='M11.5268 12.4667C11.5268 11.5505 11.9319 11.0018 12.7043 11.0018C13.4118 11.0018 13.7575 11.5278 13.7575 12.4667V15.8943H15.6802V11.7597C15.6802 10.012 14.735 9.16357 13.4118 9.16357C12.0885 9.16357 11.5322 10.2439 11.5322 10.2439V9.36154H9.67969V15.8943H11.5322V12.4667H11.5268Z'
        fill='white'
      />
    </svg>
  )
}

export default LinkedinIcon
