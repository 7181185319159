/** @jsx jsx */
import { css } from '@emotion/react'
import { colors } from './layout.styles'

export const navLinksSection = css`
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  nav{
    width: 100%;
  }
  a{
    text-transform: uppercase;
  }
  /* a:first-of-type {
    margin-right: 48px;
  } */
  ul {
    width: 100%;
    justify-content: space-around;
    li:last-of-type {
      a {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 850px) {
    display: none !important;
  }
`

export const socialMedia = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  .nav-social {
    display: flex;
    align-items: center;
    @media (max-width: 850px) {
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
  /* a:first-child {
    margin-right: 12px;
  } */
  a:nth-child(2) {
    margin-left: 12px;
    @media (max-width: 850px) {
      margin-right: 13px;
    }
  }

  a:hover {
    path {
      stroke: #badfe5;
    }
    .facebookIcon {
      fill: #badfe5;
    }
  }
  a:active {
    path {
      stroke: #ef7b8e;
    }
    .facebookIcon {
      fill: #ef7b8e;
    }
  }

  .fale-btn {
    padding: 12px 22px;
    background-color: #ef7b8e;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    color: #f9f9f9;

    &:hover {
      background-color: #8a588d;
    }
    &:active {
      background-color: #375f92;
    }
  }
  .mobile {
    display: flex;
    padding: 12px 22px;
    font-size: 16px;
    @media (min-width: 850px) {
      display: none;
    }
  }
  .desktop {
    display: flex;
    @media (max-width: 850px) {
      display: none;
    }
  }
`

export const header = css`
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  background: #f7e2d8;
  height: 120px;
  display: flex;
  align-items: center;
  .navRow {
    display: flex;
    gap: 70px;
    @media (max-width: 850px) {
      gap: 13px;
    }
  }
  .headerContainer {
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;

      .row{
          flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      }
    }
  }
`

export const logo = css`
  display: flex;
  align-items: center;
  padding: 0;
  @media (max-width: 850px) {
    img {
      width: 139px;
    }
    padding: 0;
  }
`

export const mainMenu = css`
  display: flex;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  a {
    color: #375f92;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    white-space: nowrap;
  }
  a:hover {
    color: #ef7b8e;
  }
`

export const submenu = css``

export const navigator = css``
