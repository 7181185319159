/** @jsx jsx */
import { Col, Container, Row, Form, Button } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import {
  cardSectionStyle,
  cardsContainer,
} from '../assets/styles/CardSection.styles'
import { cardsContent } from '../data'
import Card from './card'
const CardsSection = () => {
  return (
    <section css={cardSectionStyle} id="agenda">
      <Container data-aos='fade-down'>
        <Container className='titleContainer'>
          <h1>Tecnologia como aliada do aprendizado</h1>
          <p>
          <span>Além do projeto socioemocional</span>, oferecemos uma Agenda Digital para facilitar a comunicação entre escolas, professores, pais e alunos. Nossa Agenda Digital traz uma série de recursos para tornar a rotina escolar ainda mais eficiente e conectada.
          </p>
        </Container>
        <Container css={cardsContainer}>
          {cardsContent.map((data, index) => (
            <Card key={index} data={data} />
          ))}
        </Container>
      </Container>
    </section>
  )
}

export default CardsSection
