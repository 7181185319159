/** @jsx jsx */
import { jsx } from '@emotion/react'

import { containerCard } from '../assets/styles/CardSwiper.styles'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css'
import 'swiper/css/pagination';

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import photoTeacher from '../assets/images/LP-images/photo-teacher.png'



function CardSlider() {
    return (
        <div css={containerCard}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={0}
                loop={false}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}

                
                breakpoints={{
                    851: {
                        spaceBetween: 42,
                    },
                    510: {
                        spaceBetween: 42,
                    },
                }}
                className='swiper'
            >
                <SwiperSlide>
                    <div className='swiper-slide__container'>
                        <div className='icon'>
                            <img src={photoTeacher} alt='foto do professor' />
                        </div>

                        <div className='text'>
                            <h3>Luiz Carlos</h3>
                            <h5>Professor de Filosofia</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-slide__container'>
                        <div className='icon'>
                            <img src={photoTeacher} alt='foto do professor' />
                        </div>

                        <div className='text'>
                            <h3>Luiz Carlos</h3>
                            <h5>Professor de Filosofia</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-slide__container'>
                        <div className='icon'>
                            <img src={photoTeacher} alt='foto do professor' />
                        </div>

                        <div className='text'>
                            <h3>Luiz Carlos</h3>
                            <h5>Professor de Filosofia</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-slide__container'>
                        <div className='icon'>
                            <img src={photoTeacher} alt='foto do professor' />
                        </div>

                        <div className='text'>
                            <h3>Luiz Carlos</h3>
                            <h5>Professor de Filosofia</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-slide__container'>
                        <div className='icon'>
                            <img src={photoTeacher} alt='foto do professor' />
                        </div>

                        <div className='text'>
                            <h3>Luiz Carlos</h3>
                            <h5>Professor de Filosofia</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-slide__container'>
                        <div className='icon'>
                            <img src={photoTeacher} alt='foto do professor' />
                        </div>

                        <div className='text'>
                            <h3>Luiz Carlos</h3>
                            <h5>Professor de Filosofia</h5>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
            <div className='swiper-pagination' />
        </div>
    )
}

export default CardSlider
