/** @jsx jsx */
import { css } from '@emotion/react'
export const sectionStyles = css`
  background-color: #F7E2D8;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  @media (max-width: 850px) {
    .container {
      padding: 0;
      max-width: 100%;
    }
  }

  .col-text{
    margin-top: 103px;
    a {
      color: #f9f9f9;
      padding: 12px 22px;
      white-space:nowrap;
      background-color: #375F92;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      text-decoration: none !important;
      &:hover{
        background-color: #BADFE5;
        color: #375F92;
        text-decoration: none !important;
      }
      &:active{
        background-color: #EF7B8E;
      }
      @media (max-width: 850px) {
        align-self: flex-start;
        background-color: #EF7B8E;
        color: #FFFFFF;
        display: flex;
        margin-left: 16px;
      }
    }

    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #375F92;
      margin:0;
      padding-top: 56px;
      padding-bottom: 64px;
    }
  }
  .title-content {
    h1 {
      color: #375F92;
      width: 460px;
      font-size: 44px;
      font-weight: 700;
      line-height: 100%;
      @media (max-width: 850px) {
        width: 100%;
        text-align: center;
        color: #F1F3F4;
      }
    }
    p {
      width: 440px;
      color: #666;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 32px;
    }
    @media (max-width: 850px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 44px;
        font-weight: 700;
        line-height: 48.4px;
        margin-bottom: 16px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;
      }
      p {
        color:#FFFFFF; 
        font-size: 18px;
        line-height:27px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;

      }
      img {
        margin-bottom: 56px;
        width: 350px;
        @media (max-width: 850px) {
        margin: 0;
      }
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }
`


