/** @jsx jsx */
import { css } from '@emotion/react'

export const styles = css`
    width: 350px;
    height: 632px;
    border: 1px solid #666;
    border-radius: 20px;
    &:hover{
        background-color: #F7E2D8;
    }
    img {
        width: 350px;
        height: 201px;
        padding-top: 69px;
    }

    h3 {
        margin-top: 32px;
        margin-bottom: 16px;
        color: #375F92;
        font-size: 28px;
        font-weight: 700;
        line-height: 100%;
    }
    h5 {
        color: #666;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0;
    }
    p {
        color: #666;
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
    }
    .content-text{
        padding-inline: 24px;
    }
`