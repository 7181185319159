/** @jsx jsx */
import { css } from "@emotion/react";

export const formSectionStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #badfe5;
  padding-top: 40px;
  h1 {
    color: #375f92;
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    line-height: 100%;
    padding-top: 48px;
    padding-bottom: 8px;
    margin: 0;
    @media (max-width: 500px) {
      font-size: 38px;
      text-align: center;
    }
  }
  p {
    font-size: 18px;
    line-height: 27px;
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  img {
    @media (max-width: 500px) {
      width: 375px;
    }
  }
  .form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  .form-div {
    /* background-color: #375f92; */
    display: flex;
    justify-content: center;
    height: 556px;
    border-radius: 16px;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 380px;
    height: 556px;
    gap: 8px;
    /* align-items: center; */
    border-radius: 16px;

    button {
      align-self: flex-start;
      align-items: center;
      border-radius: 4px;
      background-color: #375f92;
      border: none;
      color: #f9f9f9;
      display: flex;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      justify-content: center;
      line-height: 150%;
      margin-top: 32px;
      padding: 12px 22px;
      width: fit-content;
      @media (max-width: 500px) {
        align-self: center;
      }
      &:hover {
        background-color: #8A588D;
        color: #ffffff;
      }
      &:active {
        background: #EF7B8E;
      }
    }
    input {
      border: 1px solid #375f92;
      border-radius: 12px;
      width: 100%;
      height: 35px;
      margin-bottom: 8px;
      position: relative;
      padding-left: 16px;
      font-size: 16px;

      &::placeholder {
        font-size: 16px;
        font-family: Montserrat;
        color: #375f92;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
        margin-top: 0;
      }
    }
    textarea {
      border: none;
      border-radius: 8px;
      width: 100%;

      height: 54px;
      position: relative;

      &::placeholder {
        font-size: 18px;
        font-family: Montserrat;
        color: #375f92;
        font-weight: 700;
        line-height: 120%;
      }

      &::placeholder {
        position: absolute;
        top: 16px;
        left: 0;
      }
    }
    #mensagem {
      height: 92px;
    }
  }

  .col-img {
    display: flex;
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;
    }
  }
`;
