/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { sectionStyles } from '../assets/styles/Pillars.styles'

import pillarsBg from '../assets/images/LP-images/pillars-background.png'
import pillarsBgMobile from '../assets/images/LP-images/pillars-mobile-bg.png'

const Pillars = () => {
  return (
    <section css={sectionStyles}>
      <Container>
        <div className='pillars'>
          <h1 data-aos='fade-down'>Nossos pilares</h1>
        </div>
        <div>
          <Row className='rowCustom' >
            <Col md={12} lg={3}>
              <p data-aos='fade-left'><span className='circle'></span>Autoconsciência</p>
              <p data-aos='fade-right'><span className='circle'></span>Habilidades de Relacionamento</p>
            </Col>
            <Col md={12} lg={3}>
              <p data-aos='fade-left'><span className='circle'></span>Inovação</p>
              <p data-aos='fade-right'><span className='circle'></span>Pensamento Crítico</p>
            </Col>
            <Col md={12} lg={3}>
              <p data-aos='fade-left'><span className='circle'></span>Comunicabilidade</p>
              <p data-aos='fade-right'><span className='circle'></span>Tomada de Decisão</p>
            </Col>
            <Col md={12} lg={3}>
              <p data-aos='fade-left'><span className='circle'></span>Resiliência</p>
              <p data-aos='fade-right'><span className='circle'></span>Consciência Coletiva</p>
            </Col>
          </Row>
        </div>
        <img className='bg' src={pillarsBg} alt="plantas" />
        <img className='bgMobile' src={pillarsBgMobile} alt="plantas" />
      </Container>
    </section>
  )
}

export default Pillars
