/** @jsx jsx */
import { css } from '@emotion/react'
export const sectionStyles = css`
  background-color: #EF7B8E;
  display: flex;
  flex-direction: column;
  height: 360px;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  @media (max-width: 850px) {
    height: 496px;
  }
 

  .development {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: #EF7B8E;
    width: 100%;
    @media (max-width: 850px) {
        gap: 24px;
      }
    h1{
      color: #fff;
      font-size: 38px;
      font-weight: 700;
      line-height: 45.6px;
      text-align: center;
      @media (max-width: 850px) {
        font-size: 36px;
        line-height: 43.2px;
        margin-top: 64px;
      }
    }
    
    p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      @media (max-width: 850px) {
        padding-bottom: 64px;
      }
    }
  }

  
    
  
`


