/** @jsx jsx */
import { jsx } from '@emotion/react'

import { formSectionStyles } from '../assets/styles/FormSection.styles'
import { Col, Container, Row } from 'react-bootstrap'
import illustrationForm from '../assets/images/LP-images/illustration-contact.svg'

const HowToWork = () => {
  return (
    <section css={formSectionStyles}>
      <Container>
        <Row>
          <Col data-aos='fade-right'>
            <Container id="contato" className='form-container'>
              <div id='formulario' className='form-div'>

                <form
                  name='agendar-visita'
                  method='POST'
                  action='/'
                  data-netlify='true'
                >
                  <h1>Conheça o<br/> Versar na prática</h1>
                  <p>Preencha os campos abaixo e aguarde nosso time entrar em contato</p>
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita'
                  />

                    <input
                      type='text'
                      name='nome'
                      placeholder='Nome'
                      required
                    />
                    <input
                      type='tel'
                      name='telefone'
                      placeholder='Telefone'
                      required
                    />
                    <input
                      type='text'
                      name='cargo'
                      placeholder='Cargo'
                      required
                    />
                    <input
                      type='text'
                      name='empresa'
                      placeholder='Empresa'
                      required
                    />
                    <input
                      type='text'
                      name='site'
                      placeholder='Site da Empresa'
                      required
                    />

                  <button type='submit'>
                    Tenho interesse
                  </button>
                </form>
              </div>
            </Container>
          </Col>
          <Col className='col-img' data-aos='fade-left'>
            <img src={illustrationForm} alt='Garota com celular a funco enviando email' />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HowToWork
