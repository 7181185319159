/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { sectionStyles } from '../assets/styles/AboutTwo.styles'

import GirlThumbUp from '../assets/images/LP-images/girl-thumb-up.png'
import { useMediaQuery } from 'react-responsive'

const AboutTwo = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
  return (
    <section css={sectionStyles} id="o-jeito-versar">
      <Container>
        <Row className='title-content'>
          <Col
            className='img-container'
            data-aos='fade-left'
            lg={6}
            md={12}
            sm={12}
            style={{ display: 'flex', alignSelf: 'flex-end' }}
          >
            <img src={GirlThumbUp} alt='Menina fazendo sinal de positivo sorrindo, com celular na mão' />
          </Col>
          <Col className='col-text' lg={6} md={12} sm={12} data-aos='fade-right'>
            { isMobile ? <h1>O jeito Versar <br /> de desenvolver o socioemocional</h1> : <h1>O jeito Versar de desenvolver o socioemocional</h1>}
            <p>
              Somos uma ferramenta de apoio formativo, vivencial e científico, que envolve familiares e educadores no processo de concretização das habilidades socioemocionais. Nosso objetivo é ampliar a compreensão da comunidade escolar sobre as habilidades e os valores que tornam únicos os estudantes de cada segmento (Educação Infantil, Fundamental I e II, e Ensino Médio).
            </p>
            <p>
              Nossos conteúdos são desenhados por professores e psicólogos especialistas e são adaptados para pais, professores e alunos, de modo que cada público compreenda, na linguagem adequada, cada temática.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutTwo
