/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { sectionStyles } from '../assets/styles/ConnectionBNCC.styles'

import BNCCkids from '../assets/images/LP-images/bncc-kids.png'

const ConnectionBNCC = () => {
  return (
    <section css={sectionStyles} id="bncc">
      <Container>
        <Row className='title-content'>
          <Col
            className='img-container'
            data-aos='fade-left'
            lg={6}
            md={12}
            sm={12}
          >
            <img src={BNCCkids} alt='Crianças se abraçando' />
          </Col>
          <Col className='col-text' lg={6} md={12} sm={12} data-aos='fade-right'>
            <h1>Conexão com a BNCC: Por que é importante?</h1>
            <p>
              A Base Nacional Comum Curricular (BNCC) propõe uma abordagem inovadora e contemporânea. Ela destaca a necessidade de cultivar a reflexão crítica e fomentar o exercício da cidadania nos alunos. Isso requer a adoção de estratégias de aprendizagem que sejam não apenas flexíveis, mas também abrangentes, integrando habilidades socioemocionais em todos os aspectos do currículo.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ConnectionBNCC
