/** @jsx jsx */
import { Container } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { sectionStyles } from '../assets/styles/Development.styles'

const Development = () => {
  return (
    <section css={sectionStyles}>
      <Container>
        <div className='development'>
          <h1 data-aos='fade-down'>
            Desenvolvimento integral como foco
          </h1>
          <p data-aos='fade-up'>
            Atuamos como uma estratégia pedagógica integral para potencializar uma formação cidadã e permitir que crianças e jovens sejam protagonistas da própria vida, tenham a capacidade de tomar decisões e consigam lidar com os desafios dos novos tempos.
          </p>
        </div>
      </Container>
    </section>
  )
}

export default Development
