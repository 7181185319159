/** @jsx jsx */
import { css } from '@emotion/react'


export const sectionStyles = css`
  background-color: #BADFE5;
  position: relative;
  padding: 80px 0;
  /* display: flex;
  flex-direction: column;
  height: 358px;
  height: fit-content;
  justify-content: center;
  overflow-x: hidden;
  width: 100%; */
  h1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 45.6px;
    margin-bottom: 38px;
    color: #375F92;
    @media (max-width: 850px) {
    margin-top: 30px;
  }
  }
  p{
    color: #375F92;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  p:first-of-type {
    margin-bottom: 16px;
  }

  .circle {
    background-color: #8A588D;
    border-radius: 50%;
    border: 5px solid #8A588D;
    width: 12px;
    height: 12px;
    display: block;
  }
 .bg {
  position: absolute;
  bottom: 0;
  @media (max-width: 850px) {
    display: none;
  }
 }
 .bgMobile {
  position: absolute;
  bottom: 0;
  @media (min-width: 850px) {
    display: none;
  }
 }
.rowCustom {
  p {
    display: flex;
    align-items: center; 
    gap: 8px;
  }
  @media (max-width: 850px) {
    margin-bottom: 90px;
  }
}
 
    
  
`


