/** @jsx jsx */
import { css } from "@emotion/react";

export const footerStyles = css`
  height: 272px;
  background-color: #ef7b8e;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column-reverse;
    padding: 100px 0px;
    height: fit-content;
  }
  .row {
    @media (max-width: 850px) {
      gap: 56px;
      /* flex-direction: column-reverse; */

      div:nth-child(1) {
        order: 2;
      }

      div:nth-child(2) {
        order: 2;
      }

      div:nth-child(3) {
        order: 1;
      }
    }
  }
  .contact {
    display: flex;
    align-items: center;
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
    }
    a {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      text-decoration: underline;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
    span {
      white-space: nowrap;
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .developed {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    span {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .col-socialMedia {
    display: flex;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
    }
    span {
      color: #fff;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;
    }
  }
  .socialMediaIcons {
    display: flex;
    gap: 8px;
  }
`;
