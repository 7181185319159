/** @jsx jsx */
import { css } from '@emotion/react'

export const styles = css`
  display: flex;
  background-color: #34598B;
  /* padding: 89px 0 69px; */
  @media (max-width: 850px) {
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 56px;
  }
  img {
    @media (max-width: 850px) {
      width: 375px;
    }
  }
  h1 {
    color: #F1F3F4;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 16px;
    @media (max-width: 850px) {
      text-align: left;
        margin-top: 12px;
        align-self: flex-start;
        max-width: 220px;
        font-size: 38px;
    }
  }
  p {
    color: #F1F3F4;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 44px;
    max-width: 450px;
    font-family: Montserrat;
    span{
        font-weight: 600;
    }
    @media (max-width: 850px) {
      text-align: left;
      
    }
  }
  a {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    padding: 12px 22px;
    background-color: #EF7B8E;
    border-radius: 4px;
    font-family: Montserrat;
    &:hover{
      background-color: #8A588D;
      color: #FFFFFF;
      text-decoration: none;
    }
    &:active{
      background-color: #BADFE5;
    }
    @media (max-width: 850px) {
      text-align: center;
      width: fit-content;
      margin-bottom: 24px;
    }
  }
  .row{
    align-items: center;
  }
  .whiteLabel{
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;
    }
  }
  .textContent {
    a:hover{
      text-decoration: none;
    }
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
`
