/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { sectionStyles } from '../assets/styles/About.styles'

import versarApp from '../assets/images/LP-images/girl-mobile-app-desktop.png'
import bannerLeft from '../assets/images/LP-images/banner-left.png'
import bannerRight from '../assets/images/LP-images/banner-right.png'
import { useMediaQuery } from 'react-responsive'

const About = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 850px)' })
  return (
    <section css={sectionStyles}>
      <Container>
        <Row className='title-content'>
          <Col className='col-text' lg={6} md={12} sm={12} data-aos='fade-right'>
            {isMobile ? (
              <h1>Estratégias <br /> para dar voz à Educação Socioemocional na sua escola</h1>
            ) : (
              <h1>Estratégias para dar voz à Educação Socioemocional na sua escola</h1>
            )}
            <p>
              Auxiliamos a escola a elaborar e implementar habilidades socioemocionais, através de evidências e metodologias científicas conectadas a BNCC
            </p>

            <a href="#o-jeito-versar">Saiba mais</a>
          </Col>
          <Col
            className='img-container'
            data-aos='fade-left'
            lg={6}
            md={12}
            sm={12}
          >
            <img src={versarApp} alt='Menina interagindo com o celular e imagens do aplicativo versa sendo mostradas' />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default About
