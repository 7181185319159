/** @jsx jsx */
import { jsx } from '@emotion/react'
import { styles } from '../assets/styles/EspacoSection.styles'
import { Col, Container, Row } from 'react-bootstrap'
import whitelabel from '../assets/images/LP-images/white-label.png'
const Espaco = () => {
  return (
    <section css={styles}>
      <Container>
        <Row className='row'>
          <Col className='whiteLabel' data-aos='fade-right'>
            <img src={whitelabel} alt='Aplicação customizável' />
          </Col>
          <Col className='textContent' data-aos='fade-left'>
            <h1>Um espaço para<br/> chamar de seu</h1>
            <p>
            Fortalecemos e personalizamos o ambiente de aprendizagem. Modular, versátil e com uma proposta white label, nossa Agenda Digital podem ser completamente adaptados e personalizados com a identidade visual da escola!
            </p>
            {/* <a href='#'>Experimente o Versar</a> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Espaco
