/** @jsx jsx */
import { jsx } from '@emotion/react'
import Layout from "@/components/layout";
import Footer from "@/components/Footer";
import About from "@/components/about";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import CardsSection from "@/components/cards-section";
import EspacoSection from "@/components/espaco-section";
import DemoSection from "@/components/demo-section";
import FormSection from "@/components/formSection";
import Testimony from '../components/testimony';
import AboutTwo from '@/components/aboutTwo';
import Incentives from '@/components/incentives';
import ConnectionBNCC from '@/components/connectionBNCC';
import Development from '@/components/development';
import Pillars from '@/components/pillars';

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Versar | Projeto de Educação Socioemocional">
          <HeaderOne />
          <About />
          <AboutTwo />
          <Incentives />
          <ConnectionBNCC />
          <Development />
          <Pillars />
          <DemoSection />
          <CardsSection />
          <EspacoSection />
          {/* <Testimony/> */}
          <FormSection />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
