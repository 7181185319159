/** @jsx jsx */
import { css } from '@emotion/react'
export const sectionStyles = css`
  background-color: #E8E8DB;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  @media (max-width: 850px) {
    .container {
      padding: 0;
      max-width: 100%;
    }
  }

  .title-content {
    display: flex;
    gap: 70px;
    flex-direction: row;
    flex-wrap: nowrap;
    h1 {
      color: #375F92;
      font-size: 38px;
      font-weight: 700;
      line-height: 45.6px;
      margin-top: 81px;
      margin-bottom: 40px;
      width: 350px;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    p {
      width: 446px;
      color: #666;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 32px;
    }
    @media (max-width: 850px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 0;
      h1 {
        font-size: 38px;
        font-weight: 700;
        line-height: 45.6px;
        margin-bottom: 40px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;
      }
      p {
        color:#666; 
        font-size: 18px;
        line-height: 27px;
        padding-inline: 16px;
        text-align: left;
        width: 100%;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
        @media (max-width: 850px) {
        margin: 0;
      }
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }
`


