/** @jsx jsx */
import { css } from '@emotion/react'

export const cardSectionStyle = css`
  background-color: #f1f3f4;
  padding-bottom: 116px;
  overflow-x: hidden;
  @media (max-width: 850px) {
    padding-bottom: 32px;
  }
  .titleContainer {
    padding-top: 116px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 850px) {
      padding-top: 64px;
      }
    h1 {
      color: #375f92;
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0;
      /* text-transform: uppercase; */
      font-family: Montserrat;
      margin-bottom: 24px;
      @media (max-width: 850px) {
       font-size: 38px;
      }
    }
    p {
      color: #666;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      max-width: 800px;
      margin-bottom: 48px;
      font-family: Montserrat;
      letter-spacing: 0;
      span{
        font-weight: 600;
      }
      @media (max-width: 850px) {
        width: 100%;
      }
    }
  }
`
export const cardsContainer = css`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  font-family: Montserrat;
  @media (max-width: 850px) {
    justify-content: center;
  }
`
