/** @jsx jsx */
import { jsx } from '@emotion/react'
import CardSlider from './cardSwiper'
import { Container } from 'react-bootstrap'
import { styles } from '../assets/styles/Testimony.styles'

const Testimony = () => {
  return (
    <section id='parceiros' css={styles}>
      <Container>
        <h2>O que dizem sobre o Versar?</h2>
      </Container>
      <CardSlider />
    </section>
  )
}

export default Testimony
