import React from 'react'

const FooterLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='160'
      height='33'
      viewBox='0 0 160 33'
      fill='none'
    >
      <path
        d='M4.00083 5.29896V8.31949H10.2235V12.3241H4.00083V18.6463H0V1.29248H13.0642V5.29896H4.00083Z'
        fill='#F1F3F4'
      />
      <path
        d='M101.888 5.29896H96.3564V1.29248H111.419V5.29896H105.887V18.6444H101.886V5.29896H101.888Z'
        fill='#F1F3F4'
      />
      <path
        d='M147.331 1.29248H151.332V14.6379H160V18.6444H147.331V1.29248Z'
        fill='#F1F3F4'
      />
      <path
        d='M56.5564 1.29248H52.0825L44.3779 18.6444H48.78L50.3129 14.9267H50.3186L52.4666 9.76706L54.3365 5.29896L56.2082 9.76706L58.3373 14.9267H58.3392L59.8703 18.6444H64.2855L56.5564 1.29248Z'
        fill='#F1F3F4'
      />
      <path
        d='M32.5002 13.0669C33.6357 12.571 34.512 11.8567 35.1308 10.9221C35.7478 9.98743 36.0563 8.8761 36.0563 7.5881C36.0563 6.3001 35.7516 5.18308 35.1422 4.24082C34.5328 3.29857 33.6641 2.57098 32.5381 2.05996C31.4101 1.54894 30.0797 1.29248 28.5505 1.29248H21.0674V18.6444H25.0682V4.56377H28.3291C29.5308 4.56377 30.4449 4.82403 31.0695 5.34455C31.6959 5.86507 32.0082 6.61355 32.0082 7.5881C32.0082 8.56265 31.6959 9.29024 31.0695 9.81836C30.443 10.3484 29.5289 10.6124 28.3291 10.6124H25.9445L28.1587 13.8096H28.1701L31.5047 18.6444H36.3799L32.5021 13.0669H32.5002Z'
        fill='#F1F3F4'
      />
      <path
        d='M84.494 13.9065C83.6253 14.6303 82.5106 15.0672 81.2937 15.0672C78.5249 15.0672 76.2804 12.8142 76.2804 10.0349C76.2804 7.25562 78.5249 5.00257 81.2937 5.00257C82.469 5.00257 83.5477 5.41101 84.4031 6.0911L88.2885 4.34717C86.6382 2.30309 84.1193 0.996094 81.2937 0.996094C76.3201 0.996094 72.2871 5.04246 72.2871 10.0349C72.2871 15.0273 76.3201 19.0756 81.2937 19.0756C84.159 19.0756 86.7064 17.7287 88.3548 15.6352L84.494 13.9084V13.9065Z'
        fill='#F1F3F4'
      />
      <path
        d='M131.599 1.29248H127.127L119.423 18.6444H123.825L125.356 14.9267H125.362L127.51 9.76706L129.381 5.29896L131.251 9.76706L133.38 14.9267H133.382L134.913 18.6444H139.329L131.599 1.29248Z'
        fill='#F1F3F4'
      />
      <path
        d='M21.7075 27.6983C22.0065 27.571 22.3509 27.5083 22.7427 27.5083V28.8742C22.578 28.8609 22.4683 28.8552 22.4115 28.8552C21.9895 28.8552 21.6583 28.9749 21.4198 29.2104C21.1795 29.4479 21.0602 29.8031 21.0602 30.2781V32.6869H19.5859V27.5824H20.994V28.2568C21.17 28.0098 21.4085 27.8236 21.7075 27.6964V27.6983Z'
        fill='#F1F3F4'
      />
      <path
        d='M28.8079 30.5536H24.9623C25.0323 30.8708 25.195 31.1197 25.4543 31.304C25.7117 31.4882 26.0334 31.5794 26.4176 31.5794C26.6826 31.5794 26.9173 31.5395 27.1217 31.4597C27.326 31.3819 27.5172 31.2565 27.6932 31.0855L28.4786 31.9385C27.9998 32.4894 27.2996 32.7629 26.3798 32.7629C25.8063 32.7629 25.2991 32.6509 24.8582 32.4267C24.4172 32.2025 24.0766 31.891 23.8381 31.492C23.5996 31.0931 23.4785 30.641 23.4785 30.1356C23.4785 29.6303 23.5959 29.1858 23.8324 28.785C24.069 28.3822 24.3926 28.0707 24.8052 27.8465C25.2178 27.6223 25.6795 27.5103 26.1886 27.5103C26.6977 27.5103 27.1368 27.6166 27.5399 27.8332C27.943 28.0479 28.2591 28.3575 28.49 28.7584C28.719 29.1592 28.8344 29.6265 28.8344 30.1584C28.8344 30.1774 28.8249 30.3104 28.806 30.5574L28.8079 30.5536ZM25.3578 28.9122C25.1307 29.1022 24.9925 29.3606 24.9414 29.6911H27.4453C27.3942 29.3682 27.256 29.1098 27.0289 28.9179C26.8018 28.7242 26.5255 28.6292 26.1981 28.6292C25.8707 28.6292 25.583 28.7242 25.3578 28.9141V28.9122Z'
        fill='#F1F3F4'
      />
      <path
        d='M33.3834 32.4401C33.2377 32.5484 33.0598 32.6281 32.8497 32.6813C32.6378 32.7345 32.4163 32.7611 32.1836 32.7611C31.5779 32.7611 31.1105 32.6053 30.7793 32.2957C30.4481 31.986 30.2835 31.5301 30.2835 30.9298V28.8344H29.498V27.6965H30.2835V26.4541H31.7577V27.6965H33.0238V28.8344H31.7577V30.9127C31.7577 31.1274 31.8126 31.2945 31.9224 31.4104C32.0322 31.5282 32.1892 31.5852 32.3898 31.5852C32.6226 31.5852 32.8213 31.5225 32.986 31.3952L33.3834 32.4382V32.4401Z'
        fill='#F1F3F4'
      />
      <path
        d='M39.1158 28.0763C39.5038 28.4562 39.6969 29.0185 39.6969 29.7651V32.6868H38.2226V29.9931C38.2226 29.5884 38.1336 29.2864 37.9576 29.0869C37.7816 28.8874 37.5261 28.7886 37.1911 28.7886C36.8202 28.7886 36.5231 28.9045 36.3035 29.1344C36.0821 29.3662 35.9723 29.7081 35.9723 30.164V32.6868H34.498V25.6484H35.9723V28.1162C36.1673 27.9205 36.4038 27.7704 36.6801 27.6659C36.9565 27.5614 37.2593 27.5101 37.5867 27.5101C38.2169 27.5101 38.726 27.7001 39.1121 28.0801L39.1158 28.0763Z'
        fill='#F1F3F4'
      />
      <path
        d='M41.3883 26.6345C41.218 26.4768 41.1328 26.2812 41.1328 26.0456C41.1328 25.81 41.218 25.6163 41.3883 25.4567C41.5586 25.299 41.7782 25.2192 42.0507 25.2192C42.3232 25.2192 42.5428 25.2952 42.7131 25.4472C42.8834 25.5992 42.9686 25.7892 42.9686 26.0171C42.9686 26.2641 42.8834 26.4673 42.7131 26.6288C42.5428 26.7903 42.3213 26.8701 42.0507 26.8701C41.7801 26.8701 41.5586 26.7903 41.3883 26.6326V26.6345ZM41.3126 27.5825H42.7869V32.687H41.3126V27.5825Z'
        fill='#F1F3F4'
      />
      <path
        d='M49.0563 28.0763C49.4442 28.4563 49.6373 29.0186 49.6373 29.7651V32.6869H48.163V29.9931C48.163 29.5885 48.074 29.2864 47.898 29.087C47.722 28.8875 47.4665 28.7887 47.1316 28.7887C46.7606 28.7887 46.4635 28.9046 46.244 29.1344C46.0225 29.3662 45.9128 29.7082 45.9128 30.1641V32.6869H44.4385V27.5824H45.8465V28.1808C46.0415 27.9661 46.2837 27.799 46.5752 27.6831C46.8647 27.5672 47.1827 27.5083 47.529 27.5083C48.1592 27.5083 48.6683 27.6983 49.0544 28.0782L49.0563 28.0763Z'
        fill='#F1F3F4'
      />
      <path
        d='M53.437 30.6865L52.7292 31.3894V32.6888H51.2549V25.6504H52.7292V29.6341L54.8829 27.5843H56.641L54.5233 29.7462L56.8284 32.6869H55.0419L53.4351 30.6846L53.437 30.6865Z'
        fill='#F1F3F4'
      />
      <path
        d='M57.7747 32.4967C57.5987 32.3201 57.5098 32.0978 57.5098 31.8318C57.5098 31.5659 57.5987 31.3398 57.7747 31.1726C57.9507 31.0055 58.1684 30.9219 58.4276 30.9219C58.6869 30.9219 58.9027 31.0055 59.0806 31.1726C59.2566 31.3398 59.3455 31.5602 59.3455 31.8318C59.3455 32.1035 59.2566 32.3182 59.0806 32.4967C58.9046 32.6734 58.6869 32.7627 58.4276 32.7627C58.1684 32.7627 57.9526 32.6734 57.7747 32.4967Z'
        fill='#F1F3F4'
      />
      <path
        d='M65.7084 27.6983C66.0075 27.571 66.3519 27.5083 66.7437 27.5083V28.8742C66.579 28.8609 66.4692 28.8552 66.4125 28.8552C65.9904 28.8552 65.6592 28.9749 65.4208 29.2104C65.1804 29.4479 65.0612 29.8031 65.0612 30.2781V32.6869H63.5869V27.5824H64.995V28.2568C65.171 28.0098 65.4094 27.8236 65.7084 27.6964V27.6983Z'
        fill='#F1F3F4'
      />
      <path
        d='M72.8108 30.5536H68.9652C69.0352 30.8708 69.198 31.1197 69.4573 31.304C69.7146 31.4882 70.0364 31.5794 70.4206 31.5794C70.6855 31.5794 70.9202 31.5395 71.1246 31.4597C71.329 31.3819 71.5201 31.2565 71.6961 31.0855L72.4815 31.9385C72.0027 32.4894 71.3025 32.7629 70.3827 32.7629C69.8093 32.7629 69.3021 32.6509 68.8611 32.4267C68.4201 32.2025 68.0795 31.891 67.841 31.492C67.6026 31.0931 67.4814 30.641 67.4814 30.1356C67.4814 29.6303 67.5988 29.1858 67.8354 28.785C68.0719 28.3822 68.3955 28.0707 68.8081 27.8465C69.2207 27.6223 69.6825 27.5103 70.1916 27.5103C70.7007 27.5103 71.1397 27.6166 71.5428 27.8332C71.9459 28.0479 72.262 28.3575 72.4929 28.7584C72.7219 29.1592 72.8373 29.6265 72.8373 30.1584C72.8373 30.1774 72.8279 30.3104 72.8089 30.5574L72.8108 30.5536ZM69.3607 28.9122C69.1336 29.1022 68.9955 29.3606 68.9444 29.6911H71.4482C71.3971 29.3682 71.259 29.1098 71.0319 28.9179C70.8047 28.7242 70.5284 28.6292 70.201 28.6292C69.8736 28.6292 69.586 28.7242 69.3607 28.9141V28.9122Z'
        fill='#F1F3F4'
      />
      <path
        d='M74.1813 26.6345C74.0109 26.4768 73.9258 26.2812 73.9258 26.0456C73.9258 25.81 74.0109 25.6163 74.1813 25.4567C74.3516 25.299 74.5711 25.2192 74.8437 25.2192C75.1162 25.2192 75.3357 25.2952 75.5061 25.4472C75.6764 25.5992 75.7615 25.7892 75.7615 26.0171C75.7615 26.2641 75.6764 26.4673 75.5061 26.6288C75.3357 26.7903 75.1143 26.8701 74.8437 26.8701C74.573 26.8701 74.3516 26.7903 74.1813 26.6326V26.6345ZM74.1056 27.5825H75.5799V32.687H74.1056V27.5825Z'
        fill='#F1F3F4'
      />
      <path
        d='M81.8512 28.0763C82.2392 28.4563 82.4322 29.0186 82.4322 29.7651V32.6869H80.9579V29.9931C80.9579 29.5885 80.869 29.2864 80.693 29.087C80.517 28.8875 80.2615 28.7887 79.9265 28.7887C79.5555 28.7887 79.2584 28.9046 79.0389 29.1344C78.8175 29.3662 78.7077 29.7082 78.7077 30.1641V32.6869H77.2334V27.5824H78.6414V28.1808C78.8364 27.9661 79.0786 27.799 79.3701 27.6831C79.6596 27.5672 79.9776 27.5083 80.3239 27.5083C80.9541 27.5083 81.4632 27.6983 81.8493 28.0782L81.8512 28.0763Z'
        fill='#F1F3F4'
      />
      <path
        d='M89.0007 27.5845L86.8546 32.689H85.333L83.1963 27.5845H84.7179L86.1259 31.0571L87.5813 27.5845H88.9988H89.0007Z'
        fill='#F1F3F4'
      />
      <path
        d='M94.6985 30.5536H90.8529C90.9229 30.8708 91.0857 31.1197 91.345 31.304C91.6023 31.4882 91.9241 31.5794 92.3083 31.5794C92.5732 31.5794 92.8079 31.5395 93.0123 31.4597C93.2167 31.3819 93.4078 31.2565 93.5838 31.0855L94.3692 31.9385C93.8904 32.4894 93.1902 32.7629 92.2704 32.7629C91.697 32.7629 91.1898 32.6509 90.7488 32.4267C90.3078 32.2025 89.9672 31.891 89.7287 31.492C89.4903 31.0931 89.3691 30.641 89.3691 30.1356C89.3691 29.6303 89.4865 29.1858 89.723 28.785C89.9596 28.3822 90.2832 28.0707 90.6958 27.8465C91.1084 27.6223 91.5702 27.5103 92.0793 27.5103C92.5883 27.5103 93.0274 27.6166 93.4305 27.8332C93.8336 28.0479 94.1497 28.3575 94.3806 28.7584C94.6096 29.1592 94.725 29.6265 94.725 30.1584C94.725 30.1774 94.7156 30.3104 94.6966 30.5574L94.6985 30.5536ZM91.2484 28.9122C91.0213 29.1022 90.8832 29.3606 90.8321 29.6911H93.3359C93.2848 29.3682 93.1466 29.1098 92.9195 28.9179C92.6924 28.7261 92.4161 28.6292 92.0887 28.6292C91.7613 28.6292 91.4736 28.7242 91.2484 28.9141V28.9122Z'
        fill='#F1F3F4'
      />
      <path
        d='M100.61 28.0763C100.998 28.4563 101.191 29.0186 101.191 29.7651V32.6869H99.7167V29.9931C99.7167 29.5885 99.6278 29.2864 99.4517 29.087C99.2757 28.8875 99.0203 28.7887 98.6853 28.7887C98.3143 28.7887 98.0172 28.9046 97.7977 29.1344C97.5762 29.3662 97.4665 29.7082 97.4665 30.1641V32.6869H95.9922V27.5824H97.4002V28.1808C97.5952 27.9661 97.8374 27.799 98.1289 27.6831C98.4184 27.5672 98.7364 27.5083 99.0827 27.5083C99.7129 27.5083 100.222 27.6983 100.608 28.0782L100.61 28.0763Z'
        fill='#F1F3F4'
      />
      <path
        d='M106.087 32.4401C105.941 32.5484 105.763 32.6281 105.553 32.6813C105.341 32.7345 105.119 32.7611 104.887 32.7611C104.281 32.7611 103.814 32.6053 103.482 32.2957C103.151 31.986 102.987 31.5301 102.987 30.9298V28.8344H102.201V27.6965H102.987V26.4541H104.461V27.6965H105.727V28.8344H104.461V30.9127C104.461 31.1274 104.516 31.2945 104.626 31.4104C104.735 31.5282 104.892 31.5852 105.093 31.5852C105.326 31.5852 105.524 31.5225 105.689 31.3952L106.087 32.4382V32.4401Z'
        fill='#F1F3F4'
      />
      <path
        d='M107.201 32.4972C107.025 32.3205 106.936 32.0983 106.936 31.8323C106.936 31.5664 107.025 31.3403 107.201 31.1731C107.377 31.006 107.594 30.9224 107.853 30.9224C108.113 30.9224 108.328 31.006 108.506 31.1731C108.682 31.3403 108.771 31.5607 108.771 31.8323C108.771 32.104 108.682 32.3186 108.506 32.4972C108.33 32.6739 108.113 32.7632 107.853 32.7632C107.594 32.7632 107.378 32.6739 107.201 32.4972Z'
        fill='#F1F3F4'
      />
      <path
        d='M115.134 27.6983C115.433 27.571 115.778 27.5083 116.169 27.5083V28.8742C116.005 28.8609 115.895 28.8552 115.838 28.8552C115.416 28.8552 115.085 28.9749 114.847 29.2104C114.606 29.4479 114.487 29.8031 114.487 30.2781V32.6869H113.013V27.5824H114.421V28.2568C114.597 28.0098 114.835 27.8236 115.134 27.6964V27.6983Z'
        fill='#F1F3F4'
      />
      <path
        d='M122.238 30.5536H118.392C118.462 30.8708 118.625 31.1197 118.884 31.304C119.141 31.4882 119.463 31.5794 119.847 31.5794C120.112 31.5794 120.347 31.5395 120.551 31.4597C120.756 31.3819 120.947 31.2565 121.123 31.0855L121.908 31.9385C121.429 32.4894 120.729 32.7629 119.809 32.7629C119.236 32.7629 118.729 32.6509 118.288 32.4267C117.847 32.2025 117.506 31.891 117.268 31.492C117.029 31.0931 116.908 30.641 116.908 30.1356C116.908 29.6303 117.026 29.1858 117.262 28.785C117.499 28.3822 117.822 28.0707 118.235 27.8465C118.647 27.6223 119.109 27.5103 119.618 27.5103C120.127 27.5103 120.566 27.6166 120.97 27.8332C121.373 28.0479 121.689 28.3575 121.92 28.7584C122.149 29.1592 122.264 29.6265 122.264 30.1584C122.264 30.1774 122.255 30.3104 122.236 30.5574L122.238 30.5536ZM118.787 28.9122C118.56 29.1022 118.422 29.3606 118.371 29.6911H120.875C120.824 29.3682 120.686 29.1098 120.459 28.9179C120.232 28.7261 119.955 28.6292 119.628 28.6292C119.3 28.6292 119.013 28.7242 118.787 28.9141V28.9122Z'
        fill='#F1F3F4'
      />
      <path
        d='M127.827 27.835C128.215 28.0535 128.518 28.3593 128.74 28.7564C128.961 29.1515 129.071 29.6112 129.071 30.1355C129.071 30.6599 128.961 31.1215 128.74 31.5166C128.518 31.9118 128.215 32.2195 127.827 32.4361C127.439 32.6545 127.01 32.7628 126.537 32.7628C125.836 32.7628 125.305 32.5425 124.939 32.0979V32.6868H123.531V25.6484H125.006V28.1238C125.376 27.7134 125.887 27.5063 126.537 27.5063C127.01 27.5063 127.439 27.6146 127.827 27.8331V27.835ZM127.212 31.1652C127.454 30.9087 127.576 30.5668 127.576 30.1355C127.576 29.7043 127.454 29.3624 127.212 29.1059C126.97 28.8494 126.66 28.7222 126.281 28.7222C125.903 28.7222 125.592 28.8494 125.35 29.1059C125.108 29.3624 124.987 29.7043 124.987 30.1355C124.987 30.5668 125.108 30.9087 125.35 31.1652C125.592 31.4216 125.903 31.5489 126.281 31.5489C126.66 31.5489 126.97 31.4197 127.212 31.1652Z'
        fill='#F1F3F4'
      />
      <path
        d='M130.411 26.6345C130.24 26.4768 130.155 26.2812 130.155 26.0456C130.155 25.81 130.24 25.6163 130.411 25.4567C130.581 25.299 130.801 25.2192 131.073 25.2192C131.346 25.2192 131.565 25.2952 131.736 25.4472C131.906 25.5992 131.991 25.7892 131.991 26.0171C131.991 26.2641 131.906 26.4673 131.736 26.6288C131.565 26.7903 131.344 26.8701 131.073 26.8701C130.803 26.8701 130.581 26.7903 130.411 26.6326V26.6345ZM130.335 27.5825H131.809V32.687H130.335V27.5825Z'
        fill='#F1F3F4'
      />
      <path
        d='M137.65 31.5492V32.6871H133.113V31.7961L135.702 28.7224H133.18V27.5845H137.565V28.4754L134.976 31.5492H137.65Z'
        fill='#F1F3F4'
      />
      <path
        d='M138.842 32.4967C138.666 32.3201 138.577 32.0978 138.577 31.8318C138.577 31.5659 138.666 31.3398 138.842 31.1726C139.018 31.0055 139.236 30.9219 139.495 30.9219C139.754 30.9219 139.97 31.0055 140.148 31.1726C140.324 31.3398 140.413 31.5602 140.413 31.8318C140.413 32.1035 140.324 32.3182 140.148 32.4967C139.972 32.6734 139.754 32.7627 139.495 32.7627C139.236 32.7627 139.02 32.6734 138.842 32.4967Z'
        fill='#F1F3F4'
      />
    </svg>
  )
}

export default FooterLogo
