/** @jsx jsx */
import { css } from '@emotion/react'

export const styles = css`
  display: flex;
  background-color: #F1F3F4;
  padding-bottom: 48px;
  @media (max-width: 850px) {
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 56px;
  }
  img {
    @media (max-width: 850px) {
      width: 375px;
    }
  }
  h1 {
    color: #375F92;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 16px;
    @media (max-width: 850px) {
      text-align: center;
      margin-top: 12px;
      font-size: 38px;
    }
  }
  p {
    color: #666666;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 44px;
    @media (max-width: 850px) {
      text-align: center;
      
    }
  }
  a {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    padding: 12px 22px;
    background-color: #EF7B8E;
    border-radius: 4px;
    &:hover{
      background-color: #8A588D;
    }
    &:active{
      background-color: #BADFE5;
    }
    @media (max-width: 850px) {
      text-align: center;
      width: fit-content;
    }
  }
  .row{
    align-items: center;
  }
  .whiteLabel{
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
    }
  }
  .textContent {
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
`
