/** @jsx jsx */
import { jsx } from '@emotion/react'

import { footerStyles } from '../assets/styles/Support.styles'
import { commonSection } from '../assets/styles/layout.styles'

import logo from '../assets/images/LP-images/logo-fractal.svg'
import { Col, Container, Row } from 'react-bootstrap'
import FooterLogo from './footer-logo'
import InstagramIcon from './instagram-icon'
import FacebookIcon from './facebook-icon'
import LinkedinIcon from './linkedin-icon'

const Footer = () => {

  return (
    <section css={footerStyles}>
      <Container>
        <Row data-aos='fade-up' className='row'>
          <Col className='contact'>
            <span>Fale conosco:&nbsp;</span>
            <a>contato@versar.com.br</a>
          </Col>
          <Col className='developed'>
            <span>Desenvolvidor por:</span>
           <FooterLogo/>
          </Col>
          <Col className='col-socialMedia'>
            <span>Acesse nossas redes sociais:</span>
            <div className='socialMediaIcons'>
              <a target='_blank' href='https://www.instagram.com/versar.socioemocional/'>
                <InstagramIcon/>
              </a>
              <a target='_blank' href='https://facebook.com/versar.socioemocional'>
               <FacebookIcon/>
              </a>
              <a target='_blank' href='https://www.linkedin.com/company/versar-socioemocional/'>
                <LinkedinIcon/>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Footer
