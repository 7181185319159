/** @jsx jsx */
import { css } from '@emotion/react'

export const containerCard = css`
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-areas: 'btnPrev slider btnRight';
  grid-template-columns: auto minmax(auto, 1200px) auto;
  margin: auto;
  max-width: 1282px;
  padding: 0 8px;

  @media (max-width: 850px) {
    column-gap: 0;
    grid-template-areas:
      'slider slider slider'
      '_ btnPrev btnRight';
    padding: 0;
    row-gap: 40px;
  }
  .swiper-pagination{
    bottom: -8px;
  }
  .swiper-pagination-bullet{
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,16px));
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,16px));
    border: 1px solid #fff !important;
    background-color: transparent;
    opacity:1.0;
  }
  .swiper-pagination-bullet-active{
    background-color: #EF7B8E;
    border: none !important;
  }
  .swiper-slide__container{
    position: relative;
  }
  .swiper-wrapper{
    height: 370px;
    padding-top: 50px;
    @media (max-width: 500px) {
      height: 525px !important;
    }
  }
  .swiper {
    grid-area: slider;
    max-width: 1200px;
    width: 100%;
    padding-right: 36px;

    &-slide {
      background-color: #F1F3F4;
      border-radius: 20px;
      border: 1px solid #666666;
      height: 318px !important;
      max-width: 506px;
      padding: 40px 34px;
      cursor: grab;
      @media (max-width: 500px) {
        max-width: 285px;
        height: 457px !important;
      }

      &__container {
        text-align: center;

        .icon {
          border-radius: 50%;
          height: 114px;
          width: 114px;
          position: absolute;
            right: -70px;
            top: -90px;
            z-index: 999;

          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 1px solid #666666;
            @media (max-Width: 500px) {
              height: 90px;
              width: 90px;
            }
          }
        }

        .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          h3 {
            color: #375F92;
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 8px;
          }
          h5{
            color: #666666;
            font-size: 18px;
            font-weight: 600;
            line-height: 150%; 
            margin-bottom: 16px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            text-align: left;
            color : #666;
          }
        }
      }

      @media (max-width: 850px) {
        &:first-of-type {
          margin-left: 42px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 37px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }

      @media (max-width: 500px) {
        &:first-of-type {
          margin-left: 16px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 16px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 45px;
        }
      }
    }
  }

  .btn {
    align-items: center;
    background: rgba(237, 240, 247, 0.99);
    border: none;
    border-radius: 80px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    outline: none;
    width: 50px;

    &__icon {
      width: 100%;

      img {
        width: 100%;
      }
    }

    @media (max-width: 850px) {
      height: 36px;
      width: 36px;
    }

    @media (max-width: 500px) {
      background: inherit;
    }
  }
  .btn:hover {
    path {
      fill: #0c79fe;
    }
  }

  .slider-prev {
    grid-area: btnPrev;
    padding: 13px 20px 13px 17px;

    @media (max-width: 850px) {
      justify-self: end;
      margin-right: 40px;
      padding: 9px 14px 9px 12px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }

  .slider-next {
    grid-area: btnRight;
    justify-self: end;
    padding: 13px 17px 13px 20px;

    @media (max-width: 850px) {
      justify-self: start;
      margin-right: 58px;
      padding: 9px 12px 9px 14px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }
`
