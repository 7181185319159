/** @jsx jsx */
import { css } from '@emotion/react'

export const styles = css`
padding: 117px 0px 77px 0px;
height: fit-content;
background-color: #375F92;
@media (max-width: 500px) {
    padding: 56px 0px 0px 0px;
}

h2 {
    padding-bottom: 42px;
    text-align: center;
    color: #ffffff;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* text-transform: uppercase; */

    @media (max-width: 500px) {
        text-align: left;
        font-size: 38px;
        max-width: 320px;
    }
}
`